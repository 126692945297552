import logger from "@viz-ui/services/logger/logger";
import { UsageTracker } from "@visualizer/common/services/usageTracker/usageTracker";
import maxBy from "lodash/maxBy";
import StoryBoardDrilldownService from "@viz-ui/services/storyboardDrilldown/storyboardDrilldownService";
import globalFiltersStore from "../globalFilters/services/GlobalFiltersStore";
import BoardOrganizer from "../boardOrganizer/boardOrganizer.service";
import RowConfigManager from "../rowConfig/rowConfig.service";
import ExportAdapter from "../export/exportAdapter.service";
import { getLoganBasePath } from "@viz-ui/services/storyboardUrlDetection/storyboardUrlDetection";

angular
  .module("acl.storyboard.storyboard")
  .factory("Storyboard", function(
    $timeout,
    InterpretationTableDataStore,
    MetricDataStore,
    StoryboardBackend,
    UnsavedData,
    VisualizationDataStore,
    AppConfig
  ) {
    function Storyboard(initialData) {
      let that = this,
        storyboardId,
        storyboardUid,
        storyboardLink,
        storyboardName = "",
        originalStoryboardName = "",
        useDynamicStoryBoardFilter,
        storyboardCreatorId,
        storyboardData = { rowConfigs: {}, panels: [], positions: [] },
        boards = [],
        boardsWatch = [],
        rowConfigs = {},
        boardsByRow = [],
        boardsByRowWatch = [],
        boardsChangedTimeout = null;

      that.setData = function(storyboard, loadDrilldownFilters = true) {
        storyboardId = storyboard.id;
        storyboardName = storyboard.name || "";
        originalStoryboardName = storyboard.name;
        storyboardCreatorId = storyboard.creatorId;
        useDynamicStoryBoardFilter = storyboard.subset_filtering || false;
        // Need to remove this, as this old way of fetching storyboard uid
        storyboardUid = storyboard.uid;
        storyboardLink = storyboard.storyboard_link;
        if (storyboard.config) {
          storyboardData = angular.copy(storyboard.config);

          if (!storyboardData.rowConfigs) storyboardData.rowConfigs = {};
          if (!storyboardData.panels) storyboardData.panels = [];

          globalFiltersStore.load(storyboardData.globalFilters, loadDrilldownFilters);

          boards = storyboardData.panels;
          rowConfigs = storyboardData.rowConfigs;
          const positions = ExportAdapter.getExportPositions(storyboard.config.positions);
          let numberOfRows = positions.length == 0 ? 0 : maxBy(positions, "row").row + 1;
          BoardOrganizer.initBoards({ boards, positions });

          rowConfigs = ExportAdapter.getExportRowConfigs(rowConfigs, numberOfRows);
          RowConfigManager.initRowConfigs(rowConfigs, numberOfRows);

          handleBoardsChanged();
        }
      };

      that.getId = function() {
        return storyboardId;
      };

      that.getUid = function() {
        // checking if storyboard uid (old) exists else, return uid from storyboard links (new)
        return storyboardUid ? storyboardUid : storyboardLink ? storyboardLink.uid : undefined;
      };

      that.getLinkType = function() {
        return storyboardLink ? storyboardLink.access_type : "anyone";
      };

      that.getAssociatedLaunchpadGroups = function() {
        return storyboardLink ? storyboardLink.launchpad_group_ids : [];
      };

      that.getAssociatedWorkflowGroups = function() {
        return storyboardLink ? storyboardLink.group_ids : [];
      };

      that.getStoryboardLink = function() {
        return storyboardLink;
      };

      that.isDynamicStoryboardFilterEnabled = function() {
        return useDynamicStoryBoardFilter;
      };

      that.getOriginalStoryboardName = function() {
        return originalStoryboardName;
      };

      that.setOriginalStoryboardName = function(name) {
        originalStoryboardName = name;
      };

      that.getName = function() {
        return storyboardName;
      };

      that.setName = function(name) {
        storyboardName = name;
        UnsavedData.set(true);
      };

      that.isNameValid = function() {
        return !!this.getName() && this.getName().length <= 80;
      };

      that.hasUnsavedData = function() {
        return UnsavedData.get();
      };

      that.getConfig = () => {
        const boardJsonConfig = BoardOrganizer.toJsonConfig();
        const rowConfigs = RowConfigManager.toJsonConfig();
        return { ...storyboardData, ...boardJsonConfig, rowConfigs };
      };

      that.isSaved = function() {
        return !!storyboardId;
      };

      that.getCreatorId = function() {
        return storyboardCreatorId;
      };

      that.resetData = () => {
        InterpretationTableDataStore.resetData();
        MetricDataStore.resetData();
        VisualizationDataStore.resetData();
      };

      that.getBoard = function(id) {
        for (var i = 0; i < boards.length; i++) {
          if (boards[i].id == id) {
            return angular.copy(boards[i]);
          }
        }
        return null;
      };

      that.getBoards = function() {
        return BoardOrganizer.toJsonConfig().panels;
      };

      that.getBoardsWatch = function() {
        return boardsWatch;
      };

      that.getBoardsByRow = function() {
        return BoardOrganizer.getBoardRows();
      };

      that.addBoardToPosition = function(board, position) {
        BoardOrganizer.addBoard(board, position);

        UnsavedData.set(true);
        handleBoardsChanged();
      };

      that.addBoardToRow = function(board, row) {
        BoardOrganizer.addRow(row);
        BoardOrganizer.addBoard(board, { row, col: 0 });

        UnsavedData.set(true);
        handleBoardsChanged();
      };

      that.removeBoard = function(board) {
        BoardOrganizer.removeBoard(board.id);

        UnsavedData.set(true);
        handleBoardsChanged();
        UsageTracker.createEvent(".remove");
      };

      that.handleDrilldown = function(board, carryFilters) {
        let boardArgs = {
          ...board,
          storyboardId: that.getId(),
          storyboardName: that.getName(),
        };
        let drilldownFilters = carryFilters ? globalFiltersStore.getDrilldownFilters() : null;
        StoryBoardDrilldownService.processDrilldownAsync(boardArgs, drilldownFilters).then(drilldownInfo => {
          const visualizerAppOrigin =
            AppConfig.environment !== AppConfig.environments.DEV ? getLoganBasePath() : "http://localhost:8002";

          const visualizerWindow = window.open(`${visualizerAppOrigin}${drilldownInfo.url}`, "_blank");
          const intervalId = setInterval(() => {
            //Notifying visualizer app by sharing drilldown filters data
            if (visualizerWindow)
              visualizerWindow.postMessage({ ...drilldownInfo.data, drilldownNotifier: true }, visualizerAppOrigin);
          }, 10);

          //It's used to triggered from visualizer app to stop the drilldown notifier.
          window.addEventListener(
            "message",
            function(event) {
              if (event.origin !== visualizerAppOrigin) return;
              if (event.data.stopDrilldownNotifier) clearInterval(intervalId);
            },
            false
          );
        });
      };

      that.setRowHeight = function(rowIndex, height) {
        RowConfigManager.updateRowConfig(rowIndex, { height });
        UnsavedData.set(true);
        handleBoardsChanged();
      };

      that.save = function() {
        let id = this.getId();
        let name = this.getName();
        let config = this.getConfig();
        const globalInvalidFilter = AppConfig.features.deleteInvalidStoryboardGlobalFilters;
        if (globalInvalidFilter) {
          config = removeInvalidGlobalFilters(config);
          globalFiltersStore.load(config.globalFilters, false);
        }
        config.globalFilters = globalFiltersStore.toJson();
        cleanUpFilters(config.globalFilters);
        //Remove current filter flag from globalFilters before save the storyboard
        removeDynamicFilterFlags(config.globalFilters);
        if (id === undefined) {
          return StoryboardBackend.createStoryboard(name, config);
        } else {
          return StoryboardBackend.saveStoryboard(id, name, config).then(function(data) {
            that.setOriginalStoryboardName(data.name);
            return data;
          });
        }
      };

      that.saveAs = function(storyboardName, copyFilters) {
        let config = this.getConfig();
        config.sourceStoryboardId = this.getId();
        if (copyFilters) {
          config.globalFilters = globalFiltersStore.toJson();
        } else {
          config.globalFilters = [];
        }
        cleanUpFilters(config.globalFilters);
        //Remove current filter flag from globalFilters before save the storyboard
        removeDynamicFilterFlags(config.globalFilters);
        return StoryboardBackend.saveAsStoryboard(storyboardName, config);
      };

      function cleanUpFilters(filters) {
        filters.forEach(filter => {
          if (filter && filter.operator === "relative" && filter.values[2] !== "days") {
            filter.excludeToday = false;
          }
        });
      }

      //Function to remove isCurrentDynamicFilter, isCurrentSessionFilter, isSelectedDynamicFilter, flag from filters
      function removeDynamicFilterFlags(filters) {
        filters.forEach(filter => {
          if (filter.values && filter.values.length > 0 && filter.is_current_dynamic_filter) {
            filter.is_current_dynamic_filter = false;
          }
          if (filter.value && filter.is_current_dynamic_filter) {
            filter.is_current_dynamic_filter = false;
          }
          if (filter.values && filter.values.length > 0 && filter.is_current_session_filter) {
            filter.is_current_session_filter = false;
          }
          if (filter.value && filter.is_current_session_filter) {
            filter.is_current_session_filter = false;
          }
          if (filter.values && filter.values.length > 0 && filter.is_selected_dynamic_filter) {
            filter.is_selected_dynamic_filter = false;
          }
          if (filter.value && filter.is_selected_dynamic_filter) {
            filter.is_selected_dynamic_filter = false;
          }
        });
      }

      if (initialData) {
        logger.log(`storyboard set initial data - start`);
        that.setData(initialData);
        logger.log(`storyboard set initial data - end`);
      }

      that.delete = function() {
        let id = this.getId();
        if (!!id) {
          return StoryboardBackend.deleteStoryboard(id);
        }
      };

      that.getUniqTableIds = function() {
        return Array.from(
          new Set(
            BoardOrganizer.toJsonConfig()
              .panels.map(panel => panel.tableId)
              .filter(id => id !== undefined)
          )
        );
      };

      that.hasControlTests = function() {
        return that.getUniqTableIds().length > 0;
      };

      function handleBoardsChanged() {
        const boardJsonConfig = BoardOrganizer.toJsonConfig();
        boards = boardJsonConfig.panels;
        if (boardsChangedTimeout) return;
        boardsChangedTimeout = $timeout(function() {
          boardsWatch = angular.copy(boards);
          Object.freeze(boardsWatch);

          boardsByRowWatch = angular.copy(boardsByRow);
          Object.freeze(boardsByRowWatch);

          boardsChangedTimeout = null;
        });
      }

      function removeInvalidGlobalFilters(config) {
        let { globalFilters } = config;
        if (globalFilters) {
          let invalidFilters = getInvalidGlobalFiltersHelper(config);
          invalidFilters.forEach(invalidFilter => {
            globalFilters = globalFilters.map(globalFilter => {
              return {
                ...globalFilter,
                fields: globalFilter.fields.filter(field => field.table_id !== invalidFilter.table_id),
              };
            });
          });
          globalFilters = globalFilters.filter(globalFilter => globalFilter.fields.length > 0);
        }
        return {
          ...config,
          globalFilters: globalFilters,
        };
      }

      this.getInvalidGlobalFilters = function() {
        return getInvalidGlobalFiltersHelper(this.getConfig());
      };

      this.toExportGlobalFilters = function() {
        return globalFiltersStore.toExportJson();
      };

      function getInvalidGlobalFiltersHelper(config) {
        const { globalFilters, panels } = config;
        let globalFilterTables = [];
        let invalidFilters = [];
        if (globalFilters && panels) {
          globalFilters.forEach(item => {
            item.fields.forEach(table => {
              globalFilterTables.push({
                name: item.display_name,
                table_id: table.table_id,
                globalFilterId: item.id,
              });
            });
          });

          globalFilterTables.forEach(table => {
            let d = panels.find(panel => panel.tableId === table.table_id);
            if (!d) {
              invalidFilters.push(table);
            }
          });
        }
        return invalidFilters;
      }
    }

    return Storyboard;
  });
